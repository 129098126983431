import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
/*
compat/
*/

const firebaseConfig = {

    apiKey: "AIzaSyDkwKu7vrhQXcvh8Z8c-vArzpQfUjPTbcg",
  
    authDomain: "vue-test-a44d0.firebaseapp.com",
  
    projectId: "vue-test-a44d0",
  
    storageBucket: "vue-test-a44d0.appspot.com",
  
    messagingSenderId: "715599213905",
  
    appId: "1:715599213905:web:81917049eebd16ba468f70"
  
  };
  

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();