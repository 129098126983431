<template>
    <div>
        <h1>home</h1>
        <User v-slot:user="{ user }">
            
            <div v-if="user">
               <userProfile :user="user" />

               <chat-list :uid="user.uid" />
            </div>
            <Login v-else />

        </User>

    </div>
</template>

<script>
import ChatList from './ChatList.vue'
import Login from './Login'
import User from './User.vue'
import UserProfile from './UserProfile.vue'
export default {
    components: {
        Login,
        User,
        UserProfile,
        ChatList
    }
}
</script>